<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <div>
        <h1>Dogs</h1>
        <p>
          We currently have nine Spanish Mastiff dogs. They guard the compound and are pretty serious about their job.
        </p>
        <p>
          Eight of them are the puppies of a female dog who was wandering around our place for a week and a bit until we
          began feeding her. A short while later she gave birth to a litter of ten but two didn't make it. Likely she
          was unable to feed all ten.
        </p>
        <picture>
          <source media="(max-width: 500px)" srcset="../../assets/images/sm-miriamDog.jpg">
          <img src="../../assets/images/miriamDog.jpg">
        </picture>

        <p>
          All eight dogs are big and heavy. Their weight is between 40 kg and 50 kg each. The pack usually does things
          together and whoever gets into the compound will soon have all eight of them all over. They are very
          territorial.
        </p>

        <picture>
          <source media="(max-width: 500px)" srcset="../../assets/images/sm-dogsLinedUp.jpg">
          <img src="../../assets/images/dogsLinedUp.jpg">
        </picture>

        <p>
          Wikipedia has some details about the <a target="_blank"
            href="https://en.wikipedia.org/wiki/Spanish_Mastiff">Spanish Mastiff
            breed</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';

export default {
  name: "DogsEN",
  components: {
    Sidebar,
  },

};
</script>
